<template>
  <el-input
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    :value="value"
    size="mini"
    clearable
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  props: {
    value: { type: String },
    placeholder: { type: String },
  },
};
</script>

<style></style>
