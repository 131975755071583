<template>
  <dropdownCategoriesSelect
    size="mini"
    class="u-left-margin--2x"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script>
import dropdownCategoriesSelect from "../../dropdownCategoriesSelect";

export default {
  props: {
    value: { type: Array },
  },
  components: {
    dropdownCategoriesSelect,
  },
};
</script>

<style></style>
