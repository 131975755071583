<template>
  <el-select
    v-bind="$attrs"
    v-if="lookups"
    filterable
    :filter-method="filterByBigRegion"
    :value="addGroupForm.regions"
    auto-complete="one"
    @input="$emit('input', $event)"
    :size="size"
    multiple
    @change="reigonsChanged"
    :placeholder="$t('dropdownCategoriesSelectPlaceholder')"
    @visible-change="searchValue = ''"
    @click.native="onSelectClick"
  >
    <div class="el-select-dropdown__item" @click="selectAll">
      <span v-if="!isAllSelected"> {{ $t("GlobalsSelectAll") }} </span>
      <span v-else> {{ $t("GlobalsDeSelectAll") }}</span>
    </div>
    <div :key="reigon.id" v-for="reigon in filteredReigonOptions">
      <el-collapse accordion>
        <el-collapse-item name="1">
          <template slot="title">
            <el-option
              :class="{ 'u-font--large': true, 'u-font--bold': true }"
              :key="reigon.id"
              :label="reigon.name"
              @click.native="onBigReigonClick(reigon.id, addGroupForm)"
              :value="reigon.id"
            ></el-option>
          </template>
          <template>
            <el-option
              v-for="cReigon in reigon.childs.filter((item) => {
                return item.name.includes(searchValue);
              })"
              @click.native="isBigReigon = false"
              :disabled="checkReigon(reigon.id, addGroupForm)"
              :class="{ 'u-font--xsmall': true }"
              :key="cReigon.id"
              :label="cReigon.name"
              :value="cReigon.id"
            >
            </el-option
          ></template>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- </template> -->
  </el-select>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: { type: Array },
    size: {
      type: String,
      default: "normal",
    },
  },
  data() {
    return {
      filteredReigonOptions: [],
      searchValue: "",
      isAllSelected: false,
      addGroupForm: { regions: [] },
    };
  },
  watch: {
    value: {
      handler(value) {
        this.addGroupForm.regions = value;
      },
    },
    lookups: {
      deep: true,
      immediate: true,
      handler(value) {
        this.filteredReigonOptions = [...value.regions];
      },
    },
  },
  created() {
    if (this.value) this.addGroupForm.regions = this.value;
    this.isAllSelected = this.allRegionsSelected;
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    allRegionsSelected() {
      return (
        this.addGroupForm.regions.length ==
        this.lookups.regions.map((item) => {
          return item.id;
        }).length
      );
    },
  },
  methods: {
    selectAll() {
      if (this.allRegionsSelected) {
        this.addGroupForm.regions = [];
        this.isAllSelected = false;
      } else {
        this.addGroupForm.regions = this.lookups.regions.map((item) => {
          return item.id;
        });
        this.isAllSelected = true;
      }
      this.$emit("change", this.addGroupForm.regions);
      this.$emit("input", this.addGroupForm.regions);
    },
    reset() {
      this.addGroupForm.regions = [];
    },
    reigonsChanged(value) {
      this.isAllSelected = false;

      if (this.allRegionsSelected) {
        this.isAllSelected = true;
      }

      this.$emit("change", value);
      this.$emit("input", value);
    },
    onSelectClick() {
      this.filteredReigonOptions = this.lookups.regions;
    },
    filterByBigRegion(val) {
      this.searchValue = "";

      if (val) {
        this.filteredReigonOptions = this.lookups.regions.filter((reigon) => {
          let smallRegionsFilterd = false;
          for (let i = 0; i < reigon.childs.length; i++) {
            const element = reigon.childs[i];
            if (element.name.includes(val)) smallRegionsFilterd = true;
          }
          if (smallRegionsFilterd) this.searchValue = val;
          return reigon.name.includes(val) || smallRegionsFilterd;
        });
      } else {
        this.filteredReigonOptions = this.lookups.regions;
      }
    },

    checkReigon(id, obj) {
      const regions = obj.regions;
      if (regions != null) return obj.regions.includes(id);
      else return false;
    },
    onBigReigonClick(id, obj) {
      const reigon = this.lookups.regions.filter((item) => item.id == id);

      if (reigon) {
        for (let i = 0; i < reigon[0].childs.length; i++) {
          const element = reigon[0].childs[i];
          const exist = obj.regions.includes(element.id);
          if (exist) {
            const index = obj.regions.indexOf(element.id);
            if (index > -1) {
              obj.regions.splice(index, 1);
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  // width: 100%;
}
</style>
