<template>
  <div class="metric" :style="``">
    <i @click="close" class="el-icon-close u-right-margin--1x"></i>
    <div
      class="u-font--xsmall metric-text u-right-margin--1x u-left-margin--1x"
    >
      {{ label }}
    </div>
    <slot name="filterBody"></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      Type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" >
.metric {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 3px;
  margin: 0.3rem 0.6rem;
  background-color: #f2f6f8;
  .el-icon-close {
    cursor: pointer;

    &hover {
      font-weight: 900;
    }
  }
  .metric-text {
    white-space: nowrap;
  }
  .el-radio-group {
    margin: 0;
  }
}
</style>
