<template>
  <el-radio-group
    size="mini"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  >
    <el-radio-button v-for="item in state" :key="item.value" :label="item.value" ></el-radio-button>
  </el-radio-group>
</template>

<script>
export default {
  name:'dynamicTypeMetric',
  props: {
    value: { type: String },
    state:{ required:false }
  }
};
</script>

<style></style>
