<template>
  <div>
    <el-select
      filterable
      ref="dropdown"
      :value="value"
      multiple
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :disabled="disabled"
      :placeholder="placeholder"
    >
      <div class="el-select-dropdown__item" @click="selectAll">
        <span v-if="!allItemsSelected"> {{ $t("GlobalsSelectAll") }} </span>
        <span v-else> {{ $t("GlobalsDeSelectAll") }} </span>
      </div>
      <slot name="optionBody"></slot>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: { type: String, default: "" },
    source: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: { type: Boolean, default: false },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },

  computed: {
    allItemsSelected() {
      if (this.value.length > 0)
        return (
          this.value.length ==
          this.source.map((item) => {
            if (item.hasOwnProperty("id")) return item.id;
            return item.key;
          }).length
        );
      return false;
    },
  },
  methods: {
    selectAll() {
      if (this.allItemsSelected) {
        this.$emit("change", []);
        this.$emit("input", []);
      } else {
        const newCollection = this.source.map((item) => {
          if (item.hasOwnProperty("id")) return item.id;
          return item.key;
        });
        this.$emit("change", newCollection);
        this.$emit("input", newCollection);
      }
    },
    reset() {
      this.$emit("change", []);
      this.$emit("input", []);
    },
  },
  beforeDestroy() {
    this.$emit("change", []);
    this.$emit("input", []);
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
