<template>
  <dropdownSelectAll
    :source="lookups.areas ? lookups.areas : []"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  >
    <template v-slot:optionBody>
      <el-option
        v-for="item in lookups.areas ? lookups.areas : []"
        :key="item.key"
        :label="item.value"
        :value="item.key"
      >
      </el-option>
    </template>
  </dropdownSelectAll>
</template>

<script>
import dropdownSelectAll from "../../dropdownSelectAll";
import { mapGetters } from "vuex";

export default {
  props: {
    value: { type: Array },
  },
  components: {
    dropdownSelectAll,
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
