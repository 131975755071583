var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transactions-list-filter"},[_c('i',{staticClass:"el-icon-search u-right-margin--2x u-left-margin--2x"}),_vm._t("default"),_c('el-popover',{attrs:{"placement":"bottom","trigger":"click"}},[_c('div',[_c('el-input',{attrs:{"placeholder":_vm.$t('MetricFilterBoxPlaceholder'),"size":"normal"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('div',{staticStyle:{"max-height":"400px","overflow-y":"scroll"}},_vm._l((_vm.defaultFilterData.filter((i) => {
            this.metricIsOpened(i.key);
            return (
              i.label.toLowerCase().includes(this.searchText.toLowerCase()) &&
              !this.metricIsOpened(i.key)
            );
          })),function(item,index){return _c('div',{key:index,staticClass:"metric-option",on:{"click":function($event){return _vm.openMetric(item)}}},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")])}),0)],1),_c('el-button',{staticClass:"u-left-margin--2x metric-btn u-font--xsmall",attrs:{"slot":"reference","type":"primary","size":"normal","icon":"el-icon-plus"},slot:"reference"},[_vm._v(_vm._s(_vm.$t("MetricFilterBoxAddMetricBtn")))])],1),(_vm.clearBtnShowen)?_c('el-button',{staticClass:"u-left-margin--2x u-color-normal-text-color",attrs:{"type":"primary","icon":"el-icon-close"},on:{"click":_vm.clearFilters}},[_vm._v(_vm._s(_vm.$t("MetricFilterBoxAddClearFiltersBtn")))]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }