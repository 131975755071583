<template>
  <div class="transactions-list-filter">
    <i class="el-icon-search u-right-margin--2x u-left-margin--2x"></i>
    <!-- here the metrics boxes area -->
    <slot></slot>
    <el-popover placement="bottom" trigger="click">
      <div>
        <el-input
          v-model="searchText"
          :placeholder="$t('MetricFilterBoxPlaceholder')"
          size="normal"
        ></el-input>
        <div style="max-height: 400px; overflow-y: scroll">
          <div
            v-for="(item, index) in defaultFilterData.filter((i) => {
              this.metricIsOpened(i.key);
              return (
                i.label.toLowerCase().includes(this.searchText.toLowerCase()) &&
                !this.metricIsOpened(i.key)
              );
            })"
            @click="openMetric(item)"
            :key="index"
            class="metric-option"
          >
            {{ $t(item.label) }}
          </div>
        </div>
      </div>
      <el-button
        type="primary"
        slot="reference"
        size="normal"
        icon="el-icon-plus"
        class="u-left-margin--2x metric-btn u-font--xsmall"
        >{{ $t("MetricFilterBoxAddMetricBtn") }}</el-button
      >
    </el-popover>

    <el-button
      v-if="clearBtnShowen"
      type="primary"
      icon="el-icon-close"
      class="u-left-margin--2x u-color-normal-text-color"
      @click="clearFilters"
      >{{ $t("MetricFilterBoxAddClearFiltersBtn") }}</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    /**
     * all Metrics available to this filter in this page
     */
    defaultFilterData: {
      type: Array,
      required: true,
    },

    value: {
      type: Array,
      required: true,
    },

    /**
     * to show clear filters button or not
     */
    clearBtnShowen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchText: "",
    };
  },

  methods: {
    clearFilters() {
      this.$emit("clearFilters");
    },
    metricIsOpened(key) {
      const opened = this.value.some((item) => {
        return item.key == key;
      });
      return opened;
    },

    openMetric(metric) {
      let val = [...this.value];
      val.push(metric);
      this.$emit("input", val);
      /**
       * trigger onOpen of a metric
       */
      this.$emit("openMetric");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/wrench-kit/settings/_settings.global.scss";
.metric-option {
  color: #405793;
  padding: 8px 15px;
  border-bottom: 1px solid #dfe2e9;

  &:hover {
    font-weight: 900;
    cursor: pointer;
  }
}

.transactions-list-filter {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  color: #405793 !important;
  padding: 10px;
  overflow: hidden;
  min-height: 40px;
  border: 1px solid #dde4ec;
  border-radius: 6px;
  font-weight: 500 !important;

  margin-bottom: $wrench-spacing-2x;
  .metric-btn {
    color: #405793;
    font-weight: 900;
  }

  justify-content: flex-start;

  align-items: center;
  .el-radio-group {
    margin: 0 1rem;
  }
}
</style>